import React from 'react';
import Icon from '../Icon';
import { ucFirst } from '../../utils/caseconverters.js';
import PropTypes from 'prop-types';
import styles from './QuickLink.module.scss';
import { trackEvent } from '../../utils/datalayer';
import classNames from 'classnames';

const Image = ({
    image: {
        renditions: { small, smallWebp },
        alt,
        focal,
    },
}) => (
    <picture className={styles['QuickLink__ImageContainer']}>
        {smallWebp?.src && <source srcSet={smallWebp.src} type="image/webp" />}
        <img
            className={styles['QuickLink__Image']}
            src={small.src}
            alt={alt}
            style={{
                objectPosition: focal.x + ' ' + focal.y,
            }}
        />
    </picture>
);

const QuickLink = ({
    href = '/',
    icon = 'arrow',
    color = 'black',
    title = '',
    style = {},
    image,
}) => {
    return (
        <div className={styles['QuickLink']} style={style}>
            <a
                onClick={() => {
                    trackEvent(
                        'quicklinkStartpage',
                        'Startpage',
                        'Quicklink click',
                        { title }
                    );
                }}
                className={styles['QuickLink__Link']}
                href={href}>
                <span className="sr-only">{title}</span>
            </a>
            {image && <Image image={image} />}
            <div
                className={
                    styles['QuickLink__Content'] +
                    ' ' +
                    styles['QuickLink__Content--' + ucFirst(color)]
                }>
                {title && (
                    <span className={styles['QuickLink__Title']}>{title}</span>
                )}
                <span className={styles['QuickLink__Icon']}>
                    <Icon color="white" type={icon} size="large" />
                </span>
            </div>
        </div>
    );
};

QuickLink.propTypes = {
    style: PropTypes.object,
    url: PropTypes.string.isRequired,
    icon: PropTypes.string,
    color: PropTypes.oneOf(['black', 'pink']),
    title: PropTypes.string,
    image: PropTypes.object,
};

export default QuickLink;
